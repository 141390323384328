import APP_CONST from '@/constants/AppConst';
import UIkit from 'uikit';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import PaginationComponent from '../../../../commoncomponents/paginationcomponent/PaginationComponent.vue';
import { updateRosterStep3, rosterStep3Autosave, commitStaff, staffStatus } from '@/services/staff/staffService';
import { ScreenText } from '@/lang/ScreenText';
import plannerStore from '@/store/modules/plannerStore';
import SortingSearchingActions from '@/commoncomponents/smartTableComponents/sortingSearchingActions/SortingSearchingActions.vue';

@Component({
  components: {
    'pagination': PaginationComponent,
    'sorting-searching-action': SortingSearchingActions
  }
})
export default class RepairCompleteTableComponent extends Vue {
  private objScreenText: ScreenText = new ScreenText();

  dynamicScrollableClass: string = '';
  private rowItemsRoaster: Array<any> = APP_CONST.ROW_ROASTER;
  public searchNoContentCase: boolean = false;
  private currentDblClickedCell: string = '';
  private columnsRoaster: Array<any> = [];
  private defaultSelectionDrop: string = 'All Columns';
  public isSearching: boolean = false;
  private sortRequired: boolean = false;
  public sortDir: number = 2;
  private query: string = '';
  public searchCopy: string = '';
  public staffCounts: number = 0;
  private isMobileForAPiCall: boolean = false;
  private noOfRowsForMobile: number = 10;
  public lastSortedColumn: string = '';
  private mobileView: boolean = false;
  private openDropStatus: boolean = false;
  public disableFinishButton: boolean = false;

  private hovering: string = '';
  private currentHoveredCell: string = '';
  private hoverStyleObj: any = {};
  private hoveredCellValue: any = '';
  private fixUnresolved: boolean = false;
  private mobileQuery: string = '';
  sortedField: string = '';
  sortAsc: string = '1';
  sortDesc: string = '2';

  private firstNameSorted: boolean = false;
  private lastNameSorted: boolean = false;
  private birtDaySorted: boolean = false;
  public step5ParametersStore: any = {};
  public onLoadData: boolean = false;
  public no_data_roaster: string = '';
  public idsAccumulator: any = [];
  public step5Scholars: Array<any> = [];
  private rowsToShow: any = [];
  private singleClickedCell: string = '';
  private dateValue: string = '';
  public today: string = '';
  private oldValue: string = '';
  private revisionsObject: any = {};
  private popupStatus: boolean = false;
  public goPage: any = '';
  getGlobalState = getModule(GlobalModule, store);
  private displayColumnItems: Array<string> = APP_CONST.DISPLAY_COLUMNS;
  public isShowCrossIcon: boolean = false;
  private skipPopupDisplay: boolean = false;
  private requiredPopupDisplay: boolean = false;
  private arrowIssueClicked: string = '';

  public dataLoaded: boolean = false;
  private unresolvedStep3Validations: Array<any> = [];
  public totalScholars: number = 0;
  public activeCountScholars: number = 0;
  public isSearchFocused: boolean = false;
  private apiTableDetails: any = [];
  private customIndexes: Array<number> = [];
  private sortArrayIndexes: any = [];
  private sortedColumns: any = [];
  private classRoomOptions: Array<string> = APP_CONST.CLASSROOM_OPTIONS;
  private emailFields: any = ['familyContact#1Email', 'familyContact#2Email'];
  private emailCheck: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private phoneNumberFields: any = ['home#', 'emergencyContactPrimaryPhone', 'emergencyContactSecondaryPhone', 'familyContact#1Phone', 'familyContact#2Phone'];
  private dateFields: any = ['inactiveDate', 'enrollmentDate', 'birthDate', 'siteStartDate', 'siteEndDate'];
  private oldRowItemsRoaster: Array<any> = [];
  public ActiveScholarsCount: number = 0;
  private issuesContainer: Array<any> = [];
  private beforeActiveIssueObj: any = {};
  private rowCheckEmpty: any = [];
  public currentPage: any = 0;
  public pageMove: any = {
    isPageChange: false,
    pageIndex: 0
  };
  public isStep5moved: boolean = false;
  issueIndex = -1;
  isError = false;
  showPopupHeader = false;
  public positionsData = [
    { id: 5, value: 'Teacher' },
    { id: 6, value: 'Admin' },
    { id: 7, value: 'Support Staff' },
    { id: 9, value: 'Counselor' },
  ];

  public systemRolesData = [
    { id: 5, value: 'Program Admin' },
    { id: 6, value: 'Session Admin' },
    { id: 7, value: 'Staff' },
    { id: 9, value: 'No Access' }
  ];
  public sitesData = [];
  public searchicon: boolean = false;
  searchsystemRole: string = '';
  searchSite: string = '';
  sitesId: string = '';
  systemsRole: string = '';
  systemdropDownFlag: string = '';
  dropDownIndex: number = 0;
  siteDropDownIndex: number = 0;
  sitedropDownFlag: string = '';
  siteRoleId = 0;
  selectedSite: string = '';
  public filterDropdown: boolean = false;
  public sitefilterDropdown: boolean = false;
  public positionfilterDropdown: boolean = true;
  public columnErrorindexes: number[] = [];
  public isSortingSearching: boolean = true;
  public sortingDropdownStyleObj = { top: '', left: '' };
  public header: any = {};
  payload = {
    sortField: '', sortDir: 0
  };
  public systemRoleSerach: any = { systemRole: '', phoneType: '' };
  public searchLists: any = <any>[{ header: 'systemRole', values: ['Primary Account Admin', 'Account Admin', 'Program Admin', 'Session Admin', 'Staff', 'No Access'] }, { header: 'phoneType', values: ['Cell', 'Work', 'Home'] }];
  public scIndex: number = APP_CONST.MINUS_ONE;
  public attIndex: number = APP_CONST.MINUS_ONE;
  public positionSelect: any = [];

  @Emit('next') nextScreen(mode: string, revisionsObj: {}) { }
  @Emit('navigateStepper') navStepper(step: any) { }

  @Prop()
  componentName!: string;

  @Prop()
  navigateStepper!: any;

  @Prop()
  updatemapping!: any;

  @Prop()
  firstName!: string;

  @Watch('query', { deep: true })
  changeInSearchQuery(val: string, oldVal: string) {
    if (val.length == 0) {
      this.isSearching = false;
      this.searchNoContentCase = false;
      this.searchUsingQuery(val.trim());
    }
  }

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  /* istanbul ignore next */
  get sites() {
    return plannerStore.sitesList;
  }

  /* istanbul ignore next */
  get rows() {
    return this.rowItemsRoaster.filter((row: any) => {
      for (const key in row) {
        if (row.hasOwnProperty.call(row, key)) {
          const element = row[key];
          if (element.value.toLowerCase().includes(this.searchCopy.toLowerCase())) {
            return true;
          }
        }
      }
    });
  }

  /* istanbul ignore next */
  created() {
    const highestRole = Number(APP_UTILITIES.getCookie('highest_role'));
    this.systemRolesData = (highestRole === APP_CONST.SIX
      ? this.systemRolesData.splice(1, 3)
      : this.systemRolesData);
    this.isMobileForAPiCall = APP_UTILITIES.mobileAndTabletCheck();
    const { siteId, programId, roleId } = APP_UTILITIES.coreids();
    this.siteRoleId = roleId;
    if (roleId === APP_CONST.SIX) {
      this.sitesData = this.sites.filter((res: any) => {
        if (res.siteId === siteId) {
          this.selectedSite = res.siteName;
          return res;
        }
      });
    }
    else {
      this.sitesData = this.sites;
    }
    const accountId = APP_UTILITIES.getCookie('accountId');
    staffStatus(accountId).then((response: any) => {
      this.isError = response.data.isError || false;
      this.getDataForPage(1);
    });

  }

  /* istanbul ignore next */
  beforeCreate() {
    this.isMobileForAPiCall = APP_UTILITIES.mobileAndTabletCheck();
  }

  /* istanbul ignore next */
  mounted() {
    this.columnsRoaster = JSON.parse(JSON.stringify(APP_CONST.STAFF_COLUMN_ROSTER));
    document.addEventListener('scroll', this.handleScrollBehaviour);
    this.mobileView = APP_UTILITIES.isMobile();
    window.addEventListener('resize', APP_UTILITIES.debounce(this.isMobile));
  }

  callHover(keyValue: string, event: any) {
    const singleCell: any = this.$refs[keyValue];
    /* istanbul ignore else */
    if (singleCell && singleCell[0].innerText.length > 13) {
      const boundBox = event.target.getBoundingClientRect();
      const coordX = boundBox.left;
      const coordY = boundBox.top;
      this.hoverStyleObj = {
        top: (coordY + 40).toString() + 'px',
        left: (coordX + 70).toString() + 'px'
      };
      this.currentHoveredCell = keyValue;
      this.hovering = keyValue;
      this.hoveredCellValue = singleCell[0].innerText;
    }

  }

  callHoverOut() {
    this.currentHoveredCell = '';
    this.hovering = '';
    this.hoveredCellValue = '';
  }

  isMobile() {
    this.mobileView = APP_UTILITIES.isMobile();
    this.callHoverOut();
    if (this.mobileView === false) {
      this.closeSearchPopup();
    }
    if (this.mobileView) {
      this.closeFilePopup();
    }
  }

  closeFilePopup() {
    if (UIkit.modal('#file-name-confirmation')) {
      UIkit.modal('#file-name-confirmation').hide();
    }
  }

  handleScrollBehaviour() {
    this.callHoverOut();
    if (window.scrollY > 340) {
      this.fixUnresolved = true;
    }
    else {
      this.fixUnresolved = false;
    }
  }

  getDataForPage(event: any, type = '') {
    const { siteId } = APP_UTILITIES.coreids();
    /* istanbul ignore else */
    if (!this.isSearchFocused) {
      /* istanbul ignore else */
      if ((this.currentPage !== event) || type !== '') {
        this.defaultSelectionDrop = 'All Columns';
        this.changeDisplayView('All Columns');
        this.currentPage = event;
        const componentName = this.componentName;
        const siteIdentity = siteId;
        const stepNumber = componentName == 'repair-table'
          ? 3
          : componentName == 'complete'
            ? 4
            : 5;
        const stepObj = {
          siteId: siteIdentity,
          stepNumber,
          id: 0,
          active: 0,
          search: '',
          page: event,
          count: this.isMobileForAPiCall
            ? this.noOfRowsForMobile
            : 25,
          sortField: (stepNumber == 5 || stepNumber == 4)
            ? 'ScholarId'
            : '',
          sortDir: (stepNumber == 5 || stepNumber == 4)
            ? 2
            : 1
        };
        this.dataLoaded = false;
        this.getTableData(stepObj);
        this.defaultSelectionDrop = 'All Columns';
        this.changeDisplayView('All Columns');
      }
    }
  }

  searchUsingQuery(searchValue: string) {
    this.defaultSelectionDrop = 'All Columns';
    this.changeDisplayView('All Columns');
    this.searchCopy = searchValue;
  }

  scroll(event: any, type: string) {
    /* istanbul ignore else */
    if (type === 'table') {
      /* istanbul ignore else */
      if (event.target.scrollLeft > 2.5) {
        this.dynamicScrollableClass = 'scroll-set';
      }
      else {
        this.dynamicScrollableClass = '';
      }
    }
  }

  removeDblClickedCell() {
    this.currentDblClickedCell = '';
  }

  toggleSort(columnObj: any) {
    this.lastSortedColumn = columnObj.columnTitle;
    this.changeDisplayView(this.defaultSelectionDrop);
    this.isSearching = false;

    this.columnsRoaster.forEach((column: any) => {
      if (column.hasOwnProperty('order')) {
        if (column.columnTitle == columnObj.columnTitle) {
          column.order = columnObj.order === 1
            ? 2
            : 1;
        }
        else {
          if (column.order) {
            column.order = 0;
          }
        }
      }
    });

    this.sortedField = columnObj.columnTitle;
    this.sortDir = columnObj.order;

    this.callHoverOut();
    this.removeDblClickedCell();
    this.sortStaffLocally(columnObj);
    this.searchNoContentCase = false;
    this.dataLoaded = false;

  }

  sortStaffLocally(header: any) {
    const column = header.value;
    let sortedList: any = [];
    sortedList = this.rowItemsRoaster && this.rowItemsRoaster.sort((a: any, b: any) => {
      if (this.toLower(a[column].value) > this.toLower(b[column].value)) {
        return header.order === 1
          ? 1
          : -1; 
      }
      if (this.toLower(a[column].value) < this.toLower(b[column].value)) {
        return header.order === 1
          ? -1
          : 1; 
      }
      return 0;
    });

    this.clearYellowBox();
    this.rowItemsRoaster = [...sortedList];
  }

  toLower(input: any) {
    if (input && isNaN(input)) {
      return input.toLowerCase();
    }
    else {
      return input;
    }
  }

  clearYellowBox() {
    const element: any = document.getElementsByClassName('alert-risk');

    for (let i = 0; i < element.length; i++) {
      element[i].style.border = null;
      element[i].style.borderTop = null;
      element[i].style.borderLeft = null;
      element[i].style.borderRight = null;
      element[i].style.borderBottom = null;
    }
  }

  changeDisplayView(columnType: string) {
    this.rowItemsRoaster.forEach((rowItem: any, rowIndex: number) => {
      Object.keys(rowItem).forEach((rowKey: any, rowKeyIndex: number) => {
        if ((rowItem[rowKey].columnType.indexOf(columnType) !== -1) || (rowItem[rowKey].columnType.indexOf('Global') !== -1)) {
          rowItem[rowKey].isHidden = false;
        }
        else if (columnType === 'All Columns') {
          rowItem[rowKey].isHidden = false;
        }
        else {
          rowItem[rowKey].isHidden = true;
        }
      });
    });
    this.columnsRoaster.forEach((colItem: any, colIndex: number) => {
      if ((colItem.columnType.indexOf(columnType) !== -1) || (colItem.columnType.indexOf('Global') !== -1)) {
        colItem.isHidden = false;
      }
      else if (columnType === 'All Columns' && colItem.value != 'documents') {
        colItem.isHidden = false;
      }
      else if (columnType === 'All Columns' && colItem.value == 'documents') {
        colItem.isHidden = true;
      }
      else {
        colItem.isHidden = true;
      }
    });
    if (this.mobileView && this.defaultSelectionDrop != columnType) {
      this.closeSearchPopup(); 
    }
    this.defaultSelectionDrop = columnType;
    this.openDropStatus = false;
  }


  getTableData(stepObjNew: any, type: string = '') {
    const { roleId, programId } = APP_UTILITIES.coreids();
    this.searchNoContentCase = false;
    this.dataLoaded = false;
    const stepObj = {
      programId: programId,
      stepNumber: stepObjNew.stepNumber,
      id: stepObjNew.siteId,
      active: stepObjNew.active,
      search: stepObjNew.search,
      page: stepObjNew.page,
      count: stepObjNew.count,
      sortField: stepObjNew.sortField,
      sortDir: stepObjNew.sortDir,
      roleType: roleId
    };
    rosterStep3Autosave(stepObj).then((tableAutoSaveRes: any) => {
      this.dataLoaded = true;
      if (tableAutoSaveRes.status === APP_CONST.RESPONSE_200) {
        this.staffCounts = tableAutoSaveRes.data.result.count;
        this.activeCountScholars = tableAutoSaveRes.data.result.totalActiveCount;
        if (tableAutoSaveRes.data.result.results.length > 1) {

          this.onLoadData = true;
          this.clearYellowBox();
          this.rowItemsRoaster = [];
          const step3Data = {
            data: tableAutoSaveRes.data.result.results
          };
          step3Data.data.length && step3Data.data[0].cellValues.forEach((head: any, index: number) => {
            if (head.cellValue == '') {
              this.columnErrorindexes.push(index);
            }
          });
          this.unresolvedStep3Validations = tableAutoSaveRes.data.result.validationResults || [];
          this.totalScholars = tableAutoSaveRes.data.result.count;
          this.fillTabeResData(step3Data);
        }
      }
    }).catch(() => {
      this.dataLoaded = true;
    });
  }

  fillTabeResData(tableRes: any) {
    this.apiTableDetails = tableRes;
    this.rowItemsRoaster = [];
    const rowObjPrivate: any = JSON.parse(JSON.stringify({ ...APP_CONST.ROW_OBJ_TABLE_STAFF }));
    const valuesToConsider = tableRes.data[0].cellValues.map((item: any, index: number) => {
      if (item.cellValue != 'Parent Name' || item.cellValue != 'Custom field 1 AAAAA') {
        return item.cellValue;
      }
    });
    const rowObjKeysValidate: any = [];
    const customKeysValidate: any = [];
    const mappedFields: any = JSON.parse(JSON.stringify(APP_CONST.MAPPED_FIELD_STAFF));
    valuesToConsider.forEach((item: any, index: number) => {
      let itemFound = false;
      mappedFields.forEach((mapField: any, mapFieldIndex: number) => {

        if (Object.keys(mapField)[0] === item) {

          rowObjKeysValidate.push(Object.values(mapField)[0]);
          itemFound = true;
        }
      });
      if (!itemFound) {
        customKeysValidate.push(item);
      }

    });
    tableRes.data.length > 0 && tableRes.data.forEach((itemRow: any, itemRowIndex: number) => {
      if (itemRowIndex !== 0 && itemRow.cellValues.length > 0) {
        let countStart = false;
        const keysLength = Object.keys(tableRes.data[0].cellValues).length;
        if (tableRes.data[0].cellValues[0].cellValue.toLowerCase() === 'count') {
          countStart = true;
        }
        const newRowObjPrivate = JSON.parse(JSON.stringify(rowObjPrivate));
        itemRow.cellValues.forEach((rowCell: any, rowCellIndex: number) => {
          if (countStart) {
            if (rowCellIndex !== 0 && rowCellIndex <= (keysLength - 1)) {
              const mappedKeyValueIndex = mappedFields.map(function (item: any) {
                return Object.keys(item)[0]; 
              }).indexOf(tableRes.data[0].cellValues[rowCellIndex].cellValue);
              const mappedIndexValue: any = mappedFields[mappedKeyValueIndex] && Object.values(mappedFields[mappedKeyValueIndex])[0];
              mappedIndexValue && (newRowObjPrivate[mappedIndexValue] && (newRowObjPrivate[mappedIndexValue].value = rowCell.cellValue));
            }
          }
          else {
            /* istanbul ignore else */
            if (rowCellIndex <= (keysLength - 1)) {
              const mappedKeyValueIndexs = mappedFields.map(function (item: any) {
                return Object.keys(item)[0]; 
              }).indexOf(tableRes.data[0].cellValues[rowCellIndex].cellValue);
              const mappedIndexValues: any = mappedFields[mappedKeyValueIndexs] && Object.values(mappedFields[mappedKeyValueIndexs])[0];
              if (mappedIndexValues === 'siteName' && rowCell.id && rowCell.cellValue) {
                const found: any = this.sitesData.some((el: any) => el.siteId === rowCell.id);
                if (found) {
                  mappedIndexValues && (newRowObjPrivate[mappedIndexValues] && (newRowObjPrivate[mappedIndexValues].value = rowCell.cellValue));
                  mappedIndexValues && (newRowObjPrivate[mappedIndexValues] && (newRowObjPrivate[mappedIndexValues].id = rowCell.id));
                }
                else {
                  mappedIndexValues && (newRowObjPrivate[mappedIndexValues] && (newRowObjPrivate[mappedIndexValues].value = ''));
                }
              }
              else if (mappedIndexValues === 'systemRole' && rowCell.id && rowCell.cellValue) {
                const systemRolefound: any = this.systemRolesData.find((el: any) => {
                  if (el.id === rowCell.id) {
                    return el; 
                  } 
                });
                if (((rowCell.cellValue.toLowerCase() === 'program admin') || (rowCell.cellValue.toLowerCase() === 'none'))) {
                  mappedIndexValues && (newRowObjPrivate['siteName'] && (newRowObjPrivate['siteName'].value = ''));
                  mappedIndexValues && (newRowObjPrivate['siteName'] && (newRowObjPrivate['siteName'].id = 0));
                  mappedIndexValues && (newRowObjPrivate[mappedIndexValues] && (newRowObjPrivate[mappedIndexValues].value = systemRolefound && systemRolefound.value
                    ? systemRolefound.value
                    : ''));
                  mappedIndexValues && (newRowObjPrivate[mappedIndexValues] && (newRowObjPrivate[mappedIndexValues].id = rowCell.id));
                }
                else {
                  mappedIndexValues && (newRowObjPrivate[mappedIndexValues] && (newRowObjPrivate[mappedIndexValues].value = systemRolefound.value));
                  mappedIndexValues && (newRowObjPrivate[mappedIndexValues] && (newRowObjPrivate[mappedIndexValues].id = rowCell.id));
                }
              }
              else {
                mappedIndexValues && (newRowObjPrivate[mappedIndexValues] && (newRowObjPrivate[mappedIndexValues].value = rowCell.cellValue));
              }
            }
          }

        });
        this.rowItemsRoaster.push(newRowObjPrivate);
      }
    });
    const hash = Object.create(null),
      result = [];
    const data = this.columnsRoaster;
    for (let i = 0; i < data.length; i++) {
      /* istanbul ignore else */
      if (!hash[data[i].value]) {
        hash[data[i].value] = true;
        result.push(data[i]);
      }
    }
    this.columnsRoaster = result;
    const customIndexes: any = [];
    this.columnsRoaster.forEach((columnObj: any, columnObjIndex: number) => {
      if (columnObj.columnType[0] === 'CustomFields') {
        customIndexes.push(columnObjIndex);
      }
    });
    this.customIndexes = customIndexes;
    this.sortArrayIndexes = [APP_CONST.ZERO, APP_CONST.ONE, APP_CONST.TWO, APP_CONST.THREE, APP_CONST.FOUR, APP_CONST.FIVE, APP_CONST.SIX];
    const sortedColumns = [false, false, false];
    this.customIndexes.forEach((customItem: any) => {
      sortedColumns.push(false);
    });
    this.sortedColumns = sortedColumns;
    let classroomValues: any = [];
    this.rowItemsRoaster.forEach((rowObject: any, rowObjectIndex: number) => {
      Object.keys(rowObject).forEach((rowObjectKey: any) => {
        /* istanbul ignore else */
        if (rowObjectKey === 'classroom') {
          if (rowObject[rowObjectKey].value.length !== 0) {
            classroomValues.push(rowObject[rowObjectKey].value.trim());
          }
        }
      });
    });
    classroomValues = this.removeDuplicates(classroomValues);
    classroomValues.length > 0 && (this.classRoomOptions = classroomValues);

    setTimeout(() => {
      this.autoformatPhoneNumberDate();
      this.populateIssueToResolve('createTime');
      this.oldRowItemsRoaster = JSON.parse(JSON.stringify(this.rowItemsRoaster));
    }, 500);
    this.activeScholars();
  }

  removeDuplicates(data: any) {
    return data.filter((value: number, index: number) => data.indexOf(value) === index);
  }

  autoformatPhoneNumberDate() {
    const phoneNumberFields = this.phoneNumberFields;
    const dateFields = this.dateFields;
    this.rowItemsRoaster.forEach((rowObj: any, rowObjIndex: number) => {
      Object.keys(rowObj).forEach((ObjKey: string, ObjKeyIndex: number) => {
        if (dateFields.indexOf(ObjKey) !== -1) {
          rowObj[ObjKey].value = this.generateFormattedDate(rowObj[ObjKey].value).includes('NaN')
            ? this.generateFormattedDate(rowObj[ObjKey].value.replace(/\-/g, '/'))
            : this.generateFormattedDate(rowObj[ObjKey].value);
        }
        if (phoneNumberFields.indexOf(ObjKey) !== -1) {
          rowObj[ObjKey].value = this.generateFormattedNumber(rowObj[ObjKey].value);
        }
      });
    });
  }

  generateFormattedDate(dateValue: string) {
    const newDateValue = dateValue.split('-').length == 1
      ? dateValue.split('/')
      : dateValue.split('-');
    let d, dt, mn, monthCheck, dateCheck, yy;
    if (newDateValue.length > 1) {
      d = new Date(dateValue);
      dt = d.getDate();
      mn = d.getMonth();
      mn++;
      monthCheck = JSON.stringify(mn).length === 1
        ? true
        : false;
      dateCheck = JSON.stringify(dt).length === 1
        ? true
        : false;
      yy = d.getFullYear();
      return (`${monthCheck
        ? '0'
        : ''}` + mn + '/' + `${dateCheck
        ? '0'
        : ''}` + dt + '/' + yy);
    }
    return '';
  }

  generateFormattedNumber(numberString: any) {
    if (numberString) {
      return numberString.replace(/\D+/g, '').replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, '$1-$2-$3');
    }
    else {
      return '';
    }
  }

  closeSearchPopup() {
    const filterSearch = this.$refs.filterPanels as any;
    filterSearch && filterSearch.hasOwnProperty('hide') && (filterSearch.hide());
  }

  activeScholars() {
    this.ActiveScholarsCount = 0;
    const position = ['Teacher', 'Admin', 'Support Staff', 'Counselor'];
    this.rowItemsRoaster.forEach((activeScholar: any) => {
      const otherValue: any = { other: '' };
      if (!position.includes(activeScholar.position.value)) {
        otherValue.other = activeScholar.position.value;
      }

      this.positionSelect.push(otherValue);
      if (activeScholar.status && activeScholar.status.value === 'Active') {

        this.ActiveScholarsCount += 1;
      }
    });
  }

  populateIssueToResolve(stringParam: string = '') {
    const beforeIssues: any = JSON.parse(JSON.stringify(this.issuesContainer));
    const beforePopActiveIndex = this.issuesContainer.findIndex((item: any) => item.currentActiveIndex === true);
    const beforePopLength = this.issuesContainer && this.issuesContainer.length;
    const beforeActiveIssue = this.issuesContainer[beforePopActiveIndex];
    this.beforeActiveIssueObj = beforeActiveIssue;
    this.issuesContainer = [];
    const count = 0;
    const requiredColumns = APP_CONST.REQUIRED_COLUMNS_Email;
    this.rowItemsRoaster.forEach((rowObj: any, indexRow: number) => {
      Object.keys(rowObj).forEach((rowKey: any, rowKeyIndex: any) => {
        if (requiredColumns.indexOf(rowKey) !== -1) {
          if ((rowObj['email'].value.length === 0) && rowKey === 'email' && rowObj['systemRole'].value !== 'No Access') {
            this.isError = true;
            rowObj['email'].issueType = 'emptyField';
            this.issuesContainer.push({
              issueType: 'emptyField',
              referenceCell: `${indexRow}:${rowKey}`,
              currentActiveIndex: false,
              rowId: indexRow + 1,
              count: 0,
              isRequired: requiredColumns.findIndex((item) => item === rowKey) !== -1
                ? true
                : false
            });
          }
          else if ((rowKey == 'email') && (rowObj['email'].value.length > 0) && (!this.emailCheck.test(rowObj[rowKey].value)) && (!rowObj[rowKey].value.endsWith('.com') || !rowObj[rowKey].value.endsWith('.org') || !rowObj[rowKey].value.endsWith('.net'))) {
            this.isError = true;
            rowObj[rowKey].issueType = 'emailInvalid';
            this.issuesContainer.push({
              issueType: 'emailInvalid',
              referenceCell: `${indexRow}:${rowKey}`,
              currentActiveIndex: false,
              rowId: indexRow + 1,
              count: 0,
              isRequired: requiredColumns.findIndex((item) => item === rowKey) !== -1
                ? true
                : false
            });
          }
          else if ((rowObj[rowKey].value.length === 0) && rowKey !== 'email') {
            this.isError = true;
            rowObj[rowKey].issueType = 'emptyField';
            this.issuesContainer.push({
              issueType: 'emptyField',
              referenceCell: `${indexRow}:${rowKey}`,
              currentActiveIndex: false,
              rowId: indexRow + 1,
              count: 0,
              isRequired: requiredColumns.findIndex((item) => item === rowKey) !== -1
                ? true
                : false
            });
          }
          else if ((this.phoneNumberFields.indexOf(rowKey) !== -1) && (rowObj[rowKey].value.replace(/-|\s/g, '').length < 10 || rowObj[rowKey].value.replace(/-|\s/g, '').length > 10)) {
            rowObj[rowKey].issueType = 'phoneNumberInvalid';
            this.issuesContainer.push({
              issueType: 'phoneNumberInvalid',
              referenceCell: `${indexRow}:${rowKey}`,
              currentActiveIndex: false,
              rowId: indexRow + 1,
              count: 0,
              isRequired: requiredColumns.findIndex((item) => item === rowKey) !== -1
                ? true
                : false
            });
          }
          else {
            rowObj[rowKey].issueType = '';
            const issueGet = this.issuesContainer.findIndex((issueObj) => issueObj.referenceCell === `${indexRow}:${rowKey}`);
            if (typeof (issueGet) === 'number' && issueGet !== -1) {
              this.issuesContainer.splice(issueGet, 1);
            }
          }
        }
      });
    });
    const afterPopLength = this.issuesContainer.length;
    this.issuesContainer.length > 0 && this.issuesContainer.forEach((issueObj: any, issueIndex: number) => {
      issueObj.count = count + 1;
      if (issueIndex === beforePopActiveIndex) {
        issueObj.currentActiveIndex = true;
      }
      if (afterPopLength < beforePopLength && issueIndex === 0 && ((beforePopLength - 1) === beforePopActiveIndex)) {
        issueObj.currentActiveIndex = true;
      }
      if (beforePopActiveIndex === -1 && issueIndex === 0) {
        issueObj.currentActiveIndex = true;
      }
    });
    if (stringParam.length > 0) {
      this.issuesContainer.length > 0 && (this.issuesContainer[APP_CONST.ZERO].currentActiveIndex = true);
    }
    const checkActiveIndex = this.issuesContainer.findIndex((item: any) => item.currentActiveIndex === true);
    /* istanbul ignore else */
    if (checkActiveIndex !== -1) {
      this.issuesContainer.forEach((issueStyleObj: any, issueObjIndex: number) => {
        if (checkActiveIndex === issueObjIndex && issueStyleObj.issueType === 'emptyField' || issueStyleObj.issueType === 'emailInvalid') {
          const currentCell = this.$refs[issueStyleObj.referenceCell] as any;
          /* istanbul ignore else */
          if (currentCell) {
            currentCell[0].style.border = 'none';
            currentCell[0].style.borderTop = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
            currentCell[0].style.borderLeft = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
            currentCell[0].style.borderRight = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
            currentCell[0].style.borderBottom = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
          }
        }
        else {
          const otherCell = this.$refs[issueStyleObj.referenceCell] as any;
          otherCell && (otherCell[0].style.border = `1px solid ${APP_CONST.STYLE.COLOR.NEUTRAL_500}`);
        }
      });
    }
    else {
      this.issuesContainer.forEach((issueStyleObj: any, issueObjIndex: number) => {
        if (issueObjIndex === 0) {
          const currentCell = this.$refs[issueStyleObj.referenceCell] as any;
          if (currentCell) {
            currentCell[0].style.border = 'none';
            currentCell[0].style.borderTop = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
            currentCell[0].style.borderLeft = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
            currentCell[0].style.borderRight = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
            currentCell[0].style.borderBottom = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
          }

        }
        else {
          const otherCell = this.$refs[issueStyleObj.referenceCell] as any;
          otherCell && (otherCell[0].style.border = `1px solid ${APP_CONST.STYLE.COLOR.NEUTRAL_500}`);
        }
      });
    }

    const rowCheck: any = [];
    this.rowItemsRoaster.forEach((rowObj: any, rowObjIndex: number) => {
      let issueCount = 0;
      const emptyRowCheck = {
        rowIndex: 0,
        emptyCount: 0
      };
      this.issuesContainer.forEach((issueObj: any) => {
        if (rowObjIndex === parseInt(issueObj.referenceCell.split(':')[0])) {
          issueCount++;
        }
      });
      emptyRowCheck.rowIndex = rowObjIndex;
      emptyRowCheck.emptyCount = issueCount;
      rowCheck.push(emptyRowCheck);
    });
    this.rowCheckEmpty = rowCheck;
    const splicedNew: any = [];
    if (beforeIssues.length > this.issuesContainer.length) {
      beforeIssues.forEach((item: any) => {
        if (this.issuesContainer.findIndex((itemNew: any) => itemNew.rowID == item.rowId) == -1) {
          splicedNew.push(item);
        }
      });
      const indexFinder: any = this.unresolvedStep3Validations.findIndex((item: any) => item.pageNumber == this.currentPage);
      indexFinder !== -1 && (this.unresolvedStep3Validations.splice(indexFinder, 1));
    }
    if (beforeIssues.length < this.issuesContainer.length) {
      const lastIssueIndex: number = this.issuesContainer.length - 1;
      const lastIssueAdded: any = this.issuesContainer[lastIssueIndex];
      /* istanbul ignore else */
      if (APP_CONST.REQUIRED_COLUMNS_Email.indexOf(lastIssueAdded.referenceCell.split(':')[1]) !== -1) {
        const validationObj: any = {
          cellId: 2,
          isValid: false,
          pageNumber: this.currentPage,
          rowId: lastIssueAdded.rowId,
          validatorType: 'Mandatory'
        };
        stringParam !== 'createTime' && (this.unresolvedStep3Validations.push(validationObj));
      }
    }
    const unresolvedIndex: any = this.unresolvedStep3Validations.findIndex((item: any) => item.rowId == (splicedNew.length && splicedNew[0].rowId));
    unresolvedIndex !== -1 && (this.unresolvedStep3Validations.splice(unresolvedIndex, 1));
    if (splicedNew[0] && typeof (splicedNew[0]) == 'object' && this.issuesContainer.length == 0 && (beforeIssues.length - this.issuesContainer.length) == 1) {
      const pageToMove: any = this.unresolvedStep3Validations.length > 0
        ? this.unresolvedStep3Validations[0].pageNumber
        : this.currentPage + 1;
      const pageCount = this.isMobileForAPiCall
        ? this.noOfRowsForMobile
        : 25;
      if (Math.trunc(this.totalScholars / pageCount) > this.currentPage) {
        this.pageMove = {
          isPageChange: true,
          pageIndex: pageToMove
        };
      }
    }
    else {
      this.pageMove = {
        isPageChange: false,
        pageIndex: 0
      };
    }
    if (this.issuesContainer.length <= 0) {
      this.issueIndex = -1;
    }
  }

  setAmulatorforEmergency(item: any) {
    const emergencyArray = [];
    /* istanbul ignore else */
    if (item.emergencyContacts.length > 0) {
      for (let i = 0; i < item.emergencyContacts.length; i++) {
        const data = {
          'id': item.emergencyContacts[i].id,
          'scholarProgramSiteMappingId': item.emergencyContacts[i].scholarProgramSiteMappingId
        };
        emergencyArray.push(data);
      }
    }
    return emergencyArray;
  }

  setAmulatorforFamilies(item: any) {
    const familiesArray = [];
    /* istanbul ignore else */
    if (item.families.length > 0) {
      for (let i = 0; i < item.families.length; i++) {
        const data = {
          'id': item.families[i].id,
          'scholarProgramSiteMappingId': item.families[i].scholarProgramSiteMappingId
        };
        familiesArray.push(data);
      }
    }
    return familiesArray;
  }
  calculateIndex(index: any) {
    const currentPage: any = this.currentPage;
    const pageCount = this.isMobileForAPiCall
      ? this.noOfRowsForMobile
      : 25;
    const currentNext: any = currentPage * pageCount;
    return (currentPage == 1
      ? index + 1
      : ((currentNext - pageCount) + index + 1));
  }

  checkRow(colIndex: any) {
    if (colIndex.includes('birthDate') || colIndex.includes('inactiveDate') || colIndex.includes('enrollmentDate') || colIndex.includes('siteStartDate') || colIndex.includes('siteEndDate')) {
      return true;
    }
    else {
      return false;
    }
  }



  isPaginatioShow() {
    /* istanbul ignore else */
    if (!this.isMobileForAPiCall) {
      return this.totalScholars > 25
        ? true
        : false;
    }
    else {
      return this.totalScholars > 10
        ? true
        : false;
    }
  }


  checkCellPos(rowIndex: number, colIndex: number) {


    const newPosString = `${rowIndex}:${colIndex}`;
    /* istanbul ignore else */
    if (this.currentDblClickedCell !== newPosString) {
      this.removeDblClickedCell();
    }
    const checkClickOutIssue = this.issuesContainer.findIndex((issue: any) => issue.referenceCell === this.singleClickedCell);
    const checkClickInIssue = this.issuesContainer.findIndex((issue: any) => issue.referenceCell === newPosString);
    /* istanbul ignore else */
    if (this.singleClickedCell !== newPosString) {
      if ((this.singleClickedCell.length > APP_CONST.ZERO) && (checkClickOutIssue === -1)) {
        const previousElement = this.$refs[this.singleClickedCell] as any;
        previousElement[APP_CONST.ZERO].style.border = 'none';
      }
      /* istanbul ignore else */
      if (checkClickInIssue === -1) {
        const newElement = this.$refs[newPosString] as any;
        newElement[APP_CONST.ZERO].style.border = 'none';
        newElement[APP_CONST.ZERO].style.borderTop = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
        newElement[APP_CONST.ZERO].style.borderLeft = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
        newElement[APP_CONST.ZERO].style.borderRight = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
        newElement[APP_CONST.ZERO].style.borderBottom = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
      }
    }
    this.singleClickedCell = newPosString;
  }
  makeCellEditable(rowIndex: number, colIndex: string, rowItemColumn: any) {
    const tableRef = this.$refs.repairTable as any;
    if (colIndex.includes('lastName') || colIndex.includes('fiirstName')) {
      tableRef && (tableRef.scrollLeft = 0);
    }

    if (colIndex.includes('birthDate') || colIndex.includes('inactiveDate') || colIndex.includes('enrollmentDate') || colIndex.includes('siteStartDate') || colIndex.includes('siteEndDate')) {
      this.dateValue = '';
      /* istanbul ignore else */
      if (rowItemColumn.value.length > 0) {
        this.dateValue = APP_UTILITIES.formatFullYearDate(new Date(rowItemColumn.value).toString());
      }

    }
    else {
      this.dateValue = rowItemColumn.value;
    }
    if (colIndex.includes('position') || colIndex.includes('systemRole')) {
      setTimeout(() => {
        let ele: any;
        if (colIndex == 'position') {
          ele = document.getElementById('postionDropdown');
        }
        else if (colIndex == 'systemRole') {
          ele = document.getElementById('systemRoleDropdown');
        }
        ele && ele.click();
      }, 200);

    }
    this.today = colIndex.includes('birthDate')
      ? APP_UTILITIES.formatFullYearDate(new Date().toString())
      : '';
    this.currentDblClickedCell = `${rowIndex}:${colIndex}`;
    const sortIndex = this.columnsRoaster.findIndex(o => o.value === colIndex);
    const sortColIndexUpdate = this.generateNewSortIndex(sortIndex);
    this.sortedColumns[sortColIndexUpdate] = false;
    if (colIndex.includes('status')) {
      this.oldValue = rowItemColumn.value;
    }
    this.cleardropdownFilters();
  }

  generateNewSortIndex(sortIndex: number) {
    const sortedColumnsCustomIndex: any = [];
    const lastIndexCheck = this.sortedColumns.length - 1;
    this.sortedColumns.forEach((item: any, itemIndex: number) => {
      if ((itemIndex >= 3) && itemIndex <= lastIndexCheck) {
        sortedColumnsCustomIndex.push(itemIndex);
      }
    });
    let newSortIndex = 0;
    if (this.customIndexes.indexOf(sortIndex) !== -1) {
      this.customIndexes.forEach((customIndex: number, index: number) => {
        if (sortIndex === customIndex) {
          newSortIndex = sortedColumnsCustomIndex[index];
        }
      });
      return newSortIndex;
    }
    else {
      return sortIndex;
    }

  }

  updateDateIssuesBlur(value: any, index?: number) {
    this.dateValue = value;
    if (this.phoneNumberFields.indexOf(this.currentDblClickedCell.split(':')[1]) !== -1) {
      const valueToChangeRef = this.rowItemsRoaster[parseInt(this.currentDblClickedCell.split(':')[0])][this.currentDblClickedCell.split(':')[1]].value;
      this.rowItemsRoaster[parseInt(this.currentDblClickedCell.split(':')[0])][this.currentDblClickedCell.split(':')[1]].value = this.generateFormattedNumber(valueToChangeRef);
    }
    this.changeDate(this.currentDblClickedCell, value);
    this.populateIssueToResolve();

    this.checkRevisonsMade('blurAutoSave', index);
  }

  updateIssuesBlur(value: any, index?: number) {
    this.dateValue = value;
    if (this.phoneNumberFields.indexOf(this.currentDblClickedCell.split(':')[1]) !== -1 && (this.rowItemsRoaster[parseInt(this.currentDblClickedCell.split(':')[0])][this.currentDblClickedCell.split(':')[1]])) {
      const valueToChangeRef = this.rowItemsRoaster[parseInt(this.currentDblClickedCell.split(':')[0])][this.currentDblClickedCell.split(':')[1]].value;
      this.rowItemsRoaster[parseInt(this.currentDblClickedCell.split(':')[0])][this.currentDblClickedCell.split(':')[1]].value = this.generateFormattedNumber(valueToChangeRef);
    }

    this.populateIssueToResolve();

    this.changeDate(this.currentDblClickedCell, value);
    this.checkRevisonsMade('blurAutoSave', index);
    if (value.toLowerCase() === APP_CONST.ACTIVE.toLowerCase() || value.toLowerCase() === APP_CONST.INACTIVE.toLowerCase()) {
      const newValue = this.rowItemsRoaster[parseInt(this.currentDblClickedCell.split(':')[0])][this.currentDblClickedCell.split(':')[1]].value;
      if ((value.toLowerCase() === APP_CONST.ACTIVE.toLowerCase()) && (newValue.toLowerCase() != this.oldValue.toLowerCase())) {
        this.activeCountScholars = this.activeCountScholars + APP_CONST.ONE;
      }
      else if ((value.toLowerCase() === APP_CONST.INACTIVE.toLowerCase()) && (newValue != this.oldValue)) {
        this.activeCountScholars = this.activeCountScholars - APP_CONST.ONE;
      }
    }
    this.activeCountScholars = (this.activeCountScholars < APP_CONST.ZERO)
      ? APP_CONST.ZERO
      : this.activeCountScholars;
  }

  changeDate(refernceCell: string, dateValue: string) {
    if (refernceCell.includes('birthDate') || refernceCell.includes('inactiveDate') || refernceCell.includes('enrollmentDate') || refernceCell.includes('siteStartDate') || refernceCell.includes('siteEndDate')) {
      /* istanbul ignore else */
      if (dateValue.length > 0) {
        this.rowItemsRoaster.forEach((item: any, itemIndex: number) => {
          Object.keys(item).forEach((itemkey: string, itemKeyIndex: number) => {
            const referenceEachCell = `${itemIndex}:${itemkey}`;
            if (referenceEachCell === refernceCell) {
              item[itemkey].value = this.generateFormattedDate(dateValue);
              this.dateValue = this.generateFormattedDate(dateValue);
            }
          });
        });
      }

    }

  }

  checkRevisonsMade(typeString: string = '', index?: number) {
    if (typeString == '') {
      this.disableFinishButton = true;
    }
    let itemsSkipped: any = [];
    const rowRevisionsMade: any = [];
    const rowRevisionsUpdate: any = [];
    (this.oldRowItemsRoaster && this.oldRowItemsRoaster.length && this.oldRowItemsRoaster.length === this.rowItemsRoaster.length) && (this.oldRowItemsRoaster.length > 0) && this.oldRowItemsRoaster.forEach((oldRowObj: any, oldRowObjIndex: number) => {
      Object.keys(oldRowObj).forEach((oldRowObjKey: string, oldRowObjKeyIndex: number) => {
        if ((this.dateFields.indexOf(oldRowObjKey) === -1) && (this.phoneNumberFields.indexOf(oldRowObjKey) === -1) && (oldRowObj[oldRowObjKey].value !== this.rowItemsRoaster[oldRowObjIndex][oldRowObjKey].value)) {
          rowRevisionsUpdate.push(`${oldRowObjIndex}:${oldRowObjKey}`);
          rowRevisionsMade.push(oldRowObjIndex);
        }
        if (this.phoneNumberFields.indexOf(oldRowObjKey) !== -1) {
          if ((oldRowObj[oldRowObjKey].value.replace(/-/g, '') !== this.rowItemsRoaster[oldRowObjIndex][oldRowObjKey].value.replace(/-/g, ''))) {
            rowRevisionsUpdate.push(`${oldRowObjIndex}:${oldRowObjKey}`);
            rowRevisionsMade.push(oldRowObjIndex);
          }
        }
        if (this.dateFields.indexOf(oldRowObjKey) !== -1) {
          if (this.generateFormattedDate(oldRowObj[oldRowObjKey].value) !== this.generateFormattedDate((this.rowItemsRoaster[oldRowObjIndex][oldRowObjKey].value))) {
            rowRevisionsUpdate.push(`${oldRowObjIndex}:${oldRowObjKey}`);
            rowRevisionsMade.push(oldRowObjIndex);
          }
        }

      });
    });
    itemsSkipped = this.issuesContainer.map((item: any) => item.count === 2).filter((item: any) => item === true);
    const revisionsObject = {
      rowsRevised: this.removeDuplicates(rowRevisionsMade).length,
      revisionsMade: this.removeDuplicates(rowRevisionsUpdate).length,
      itemsSkipped: itemsSkipped.length,
      arrayRowsRevised: rowRevisionsMade
    };
    this.revisionsObject = revisionsObject;
    if (typeString == '') {
      this.popupStatus = true;

    }
    this.postTableRowData(false, index);
    if (typeString == '') {
      this.goPage = '';
      this.isSearching = false;
      if (this.isError) {
        setTimeout(() => {
          if (UIkit.modal('#unresoved-issue')) {
            UIkit.modal('#unresoved-issue').show();
            this.disableFinishButton = false;
          }
        }, 10);
      }
      else {
        this.moveToProgram();
      }
    }
  }

  postTableRowData(finalSubmit: boolean, index?: number) {
    const rowsToPost: any = [];
    const apiTableDetail = this.apiTableDetails;
    const sitecellId = (this.apiTableDetails.data && this.apiTableDetails.data.length)
      ? this.apiTableDetails.data[0].cellValues.findIndex((res: any) => {
        return res.cellValue == 'Site/Session Name'; 
      })
      : 0;
    this.apiTableDetails.data && this.apiTableDetails.data.length > 0 && this.apiTableDetails.data.forEach((itemRow: any, itemRowIndex: number) => {
      if ((typeof index == 'number' && index >= 0) && (itemRowIndex === (index + 1))) {
        /* istanbul ignore else */
        let countStart = false;
        const keysLength = Object.keys(this.apiTableDetails.data[0].cellValues).length;
        if (this.apiTableDetails.data[0].cellValues[0].cellValue.toLowerCase() === 'count') {
          countStart = true;
        }
        itemRow.cellValues.forEach((rowCell: any, rowCellIndex: number) => {
          if (countStart) {
            if (rowCellIndex !== 0 && rowCellIndex <= (keysLength - 1)) {
              const valueToFind = this.apiTableDetails.data[0].cellValues[rowCellIndex].cellValue;
              const mappedKeyValueIndex = APP_CONST.MAPPED_FIELD_STAFF.map(function (item: any) {
                return Object.keys(item)[0]; 
              }).indexOf(valueToFind);
              const mappedIndexValue = APP_CONST.MAPPED_FIELD_STAFF[mappedKeyValueIndex];
              mappedIndexValue && (this.apiTableDetails.data[itemRowIndex].cellValues[rowCellIndex].cellValue = this.rowItemsRoaster[itemRowIndex - 1] && (this.rowItemsRoaster[itemRowIndex - 1][Object.values(mappedIndexValue)[0] as string].value));
            }
          }
          else {
            /* istanbul ignore else */
            if (rowCellIndex <= (keysLength - 1)) {
              const valueToFind: string = { ...apiTableDetail }.data[0].cellValues[rowCellIndex]['cellValue'];
              const mappedKeyValueIndex = APP_CONST.MAPPED_FIELD_STAFF.map(function (item: any) {
                return Object.keys(item)[0]; 
              }).indexOf(valueToFind);
              const mappedIndexValue = APP_CONST.MAPPED_FIELD_STAFF[mappedKeyValueIndex];
              const [cellvalues] = this.apiTableDetails.data;
              if (cellvalues.cellValues[rowCellIndex].cellValue == 'System Role' && this.systemsRole != '') {
                const selectedSystemRole: { id: number; name: string }[]
                  = APP_CONST.SYSTEM_ROLE.filter((role: { name: string }) => {
                    return role.name.toLowerCase().includes(this.systemsRole.toLowerCase()); 
                  });
                this.apiTableDetails.data[itemRowIndex].cellValues[rowCellIndex].cellValue = this.rowItemsRoaster[itemRowIndex - 1].systemRole.value;
                this.apiTableDetails.data[itemRowIndex].cellValues[rowCellIndex].id = selectedSystemRole[0].id;
              }
              else if (cellvalues.cellValues[rowCellIndex].cellValue == 'Site/Session Name' && this.sitesId != '') {
                this.apiTableDetails.data[itemRowIndex].cellValues[rowCellIndex].cellValue = this.rowItemsRoaster[itemRowIndex - 1].siteName.value;
                this.apiTableDetails.data[itemRowIndex].cellValues[rowCellIndex].id = this.rowItemsRoaster[itemRowIndex - 1].siteName.id;
              }
              else {
                mappedIndexValue && (this.apiTableDetails.data[itemRowIndex].cellValues[rowCellIndex].cellValue = this.rowItemsRoaster[itemRowIndex - 1] && (this.rowItemsRoaster[itemRowIndex - 1][Object.values(mappedIndexValue)[0] as string]) && (this.rowItemsRoaster[itemRowIndex - 1][Object.values(mappedIndexValue)[0] as string].value));
              }
            }
          }
        });

        rowsToPost.push(this.apiTableDetails.data[itemRowIndex]);

      }

    });
    this.callAutoSaveTable(rowsToPost, finalSubmit);

  }

  callAutoSaveTable(updatedRows: any, finalSubmit: boolean) {
    const { programId, siteId } = APP_UTILITIES.coreids();
    const componentName = this.componentName;
    const stepNumber = componentName == 'repair-table'
      ? 3
      : componentName == 'complete'
        ? 4
        : 5;
    let configureObj: any = {};
    this.systemsRole = '';
    this.sitesId = '';
    if (updatedRows.length > 0 && stepNumber == 3) {
      configureObj = {
        'programId': programId,
        'siteId': siteId,
        'rosterStep': 3,
        'step1': true,
        'step2': this.getGlobalState.step2AutoSave,
        'step3': '',
        'visibleFileName': '',
        'actualFileName': '',
        'status': 1,
        'isError': typeof this.isError !== 'boolean'
          ? false
          : this.isError
      };
      configureObj.step3 = updatedRows.length > 0
        ? updatedRows[0]
        : {};
    }
    if (!this.popupStatus && this.componentName == 'repair-table') {
      updateRosterStep3(configureObj).then((response: any) => {
        if (response.status == 200) {
          if (this.componentName == 'complete' && this.isStep5moved == true) {
            this.isStep5moved = false;
            this.$router.push('/roster');
          }
        }
      });
    }

    if ((finalSubmit === true) && this.componentName === 'repair-table') {
      const newConfigObj = {
        'programId': programId,
        'siteId': siteId,
        'rosterStep': 3,
        'step1': true,
        'step2': this.getGlobalState.step2AutoSave,
        'step3': updatedRows.length > 0
          ? updatedRows[0]
          : {},
        'visibleFileName': '',
        'actualFileName': '',
        'status': 1,
        'isError': typeof this.isError !== 'boolean'
          ? false
          : this.isError
      };
      updateRosterStep3(newConfigObj);
    }
  }

  checkForfocusOut() {
    this.isSearchFocused = false;
  }

  openDropdown(close?: boolean) {
    this.openDropStatus = this.openDropStatus
      ? APP_CONST.FALSE
      : (close
        ? APP_CONST.FALSE
        : APP_CONST.TRUE);
  }

  closeDropdown() {
    this.openDropStatus = false;
  }

  closeQuery() {
    this.mobileQuery = '';
    this.query = '';
    this.isSearching = false;
    this.isShowCrossIcon = false;
    this.mobileQuery = '';
    this.isSearchFocused = false;
    this.searchCopy = '';
  }

  checkEnterSearch(event: any) {
    this.isSearchFocused = true;
    if (this.mobileView) {
      this.query = this.mobileQuery;
      this.closeSearchPopup();
    }
    if (event.key === 'Enter') {
      if (this.query.length >= 3) {
        this.isSearching = true;
        this.isShowCrossIcon = true;
        this.searchUsingQuery(this.query.toLowerCase().trim());
      }
      else if (this.query.length < 3) {
        this.isShowCrossIcon = false;
      }
    }
  }

  openSkipPopup(arrowClickedType: string) {
    if (arrowClickedType === 'next') {
      const previousIssueIndex = this.issuesContainer.findIndex((newIssueObj: any) => newIssueObj.currentActiveIndex === true);
      this.issuesContainer[previousIssueIndex].currentActiveIndex = false;
      /* istanbul ignore else */
      if (previousIssueIndex <= this.issuesContainer.length - 2) {
        this.issuesContainer[previousIssueIndex + 1].currentActiveIndex = true;
        this.issuesContainer[previousIssueIndex + 1].count = 2;
        this.issueIndex = previousIssueIndex + 1;
      }
      else {
        this.issuesContainer[0].currentActiveIndex = true;
        this.issuesContainer[0].count = 2;
      }
      this.updateIssueContainerNext();
      this.skipPopupDisplay = false;
    }
    if (arrowClickedType === 'previous') {
      const previousIssueIndex = this.issuesContainer.findIndex((newIssueObj: any) => newIssueObj.currentActiveIndex === true);
      this.issuesContainer[previousIssueIndex].currentActiveIndex = false;
      /* istanbul ignore else */
      if (previousIssueIndex <= this.issuesContainer.length - 1) {
        /* istanbul ignore else */
        if ((previousIssueIndex - 1) !== -1) {
          this.issuesContainer[previousIssueIndex - 1].currentActiveIndex = true;
          this.issuesContainer[previousIssueIndex - 1].count = 2;
          this.issueIndex = previousIssueIndex - 1;
        }
      }
      else {
        this.issuesContainer[0].currentActiveIndex = false;
        this.issuesContainer[0].count = 2;
      }
      this.updateIssueContainerNext();
      this.skipPopupDisplay = false;
    }

  }

  getPosition(el: any) {
    let xPos = 0;
    let yPos = 0;

    while (el) {
      if (el.tagName == 'BODY') {
        // deal with browser quirks with body/window/document and page scroll
        const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
        const yScroll = el.scrollTop || document.documentElement.scrollTop;

        xPos += (el.offsetLeft - xScroll + el.clientLeft);
        yPos += (el.offsetTop - yScroll + el.clientTop);
      }
      else {
        // for all other non-BODY elements
        xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
        yPos += (el.offsetTop - el.scrollTop + el.clientTop);
      }

      el = el.offsetParent;
    }
    return {
      x: xPos,
      y: yPos
    };
  }

  updateIssueContainerNext() {
    this.issuesContainer.forEach((issueStyleObj: any, issueObjIndex: number) => {
      if (issueStyleObj.currentActiveIndex === true) {
        const currentCell = this.$refs[issueStyleObj.referenceCell] as any;
        if (currentCell) {
          currentCell[0].style.border = 'none';
          currentCell[0].style.borderTop = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
          currentCell[0].style.borderLeft = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
          currentCell[0].style.borderRight = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
          currentCell[0].style.borderBottom = `2px solid ${APP_CONST.STYLE.COLOR.WARNING_700}`;
          const tableRef = this.$refs.repairTable as any;
          if (currentCell[0].offsetLeft > 904 && !issueStyleObj.referenceCell.includes('lastName') && !issueStyleObj.referenceCell.includes('firstName')) {
            const scrollPos = currentCell[0].offsetLeft - 450;
            tableRef && (tableRef.scrollLeft = scrollPos);
          }
          else {
            tableRef && (tableRef.scrollLeft = 0);
          }
          if (currentCell[0].offsetTop > 373) {
            tableRef.scrollTop = currentCell[0].offsetTop;
            const position = this.getPosition(currentCell[0]);
            window.scrollBy({
              top: position.y - 300,
              behavior: 'smooth'
            });
          }
          else {
            tableRef.scrollTop = 0;
            const position = this.getPosition(currentCell[0]);
            window.scrollBy({
              top: position.y - 300,
              behavior: 'smooth'
            });
          }
        }
      }
      else {
        const otherCell = this.$refs[issueStyleObj.referenceCell] as any;
        if (otherCell) {
          otherCell[0].style.border = `1px solid ${APP_CONST.STYLE.COLOR.NEUTRAL_500}`;
        }
      }
    });

    const previousIssueCell = this.beforeActiveIssueObj && this.$refs[this.beforeActiveIssueObj.referenceCell] as any;
    previousIssueCell && previousIssueCell[0] && (previousIssueCell[0].innerText) && (previousIssueCell[0].innerText.length > 0) && (previousIssueCell[0].style.border = '0');

  }

  requiredPopDisplay() {
    this.requiredPopupDisplay = false;
  }

  nextIssue() {
    const previousIssueIndex = this.issuesContainer.findIndex((newIssueObj: any) => newIssueObj.currentActiveIndex === true);
    /* istanbul ignore else */
    if (previousIssueIndex !== -1) {
      /* istanbul ignore else */
      if (this.issuesContainer[previousIssueIndex].isRequired === true) {
        this.skipPopupDisplay = true;
      }
      else {
        this.requiredPopupDisplay = true;
      }
    }

    this.arrowIssueClicked = 'next';
  }

  checkIssueExistence() {
    const index = this.issuesContainer.findIndex((item) => item.currentActiveIndex === true);
    if (index >= 0) {
      this.skipPopupDisplay = true;
      this.issueIndex = index;
      return (index + 1);
    }
    else {
      return 0;
    }
  }

  prevIssue() {
    const previousIssueIndex = this.issuesContainer.findIndex((newIssueObj: any) => newIssueObj.currentActiveIndex === true);
    /* istanbul ignore else */
    if (previousIssueIndex !== -1) {
      /* istanbul ignore else */
      if (previousIssueIndex !== 0) {
        if (this.issuesContainer[previousIssueIndex].isRequired === true) {
          this.skipPopupDisplay = true;
        }
        else {
          this.requiredPopupDisplay = true;
        }
      }
    }
    this.arrowIssueClicked = 'previous';
  }

  navStep() {
    if (this.componentName === 'repair-table') {
      this.goPage = '';
      this.currentPage = 1;
      this.navStepper(APP_CONST.SCHOLAR_STEPPER[1]);
    }
    else if (this.componentName === 'complete') {
      this.goPage = '';
      this.currentPage = 1;
      this.navStepper(APP_CONST.SCHOLAR_STEPPER[2]);
    }
  }

  moveToStep5() {
    this.isStep5moved = true;
    this.callAutoSaveTable([], false);
  }

  closePopup() {
    this.popupStatus = false;
    if (UIkit.modal('#unresoved-issue')) {
      UIkit.modal('#unresoved-issue').hide();
    }
  }

  moveToProgram() {
    const { accountId, programId, siteId } = APP_UTILITIES.coreids();
    commitStaff({ accountId }).then((res: { status: number }) => {
      if (res.status === APP_CONST.RESPONSE_200) {
        this.$router.push({ name: 'Account Team' });
      }
      this.disableFinishButton = false;
    });
  }

  /* istanbul ignore next */
  destroyed() {
    document.removeEventListener('scroll', this.handleScrollBehaviour);
    window.removeEventListener('resize', this.isMobile);
  }

  cleardropdownFilters() {
    this.filterDropdown = false;
    this.dropDownIndex = 0;
    this.systemdropDownFlag = '';
    this.sitefilterDropdown = false;
    this.positionfilterDropdown = false;
    this.siteDropDownIndex = 0;
    this.sitedropDownFlag = '';
  }

  showsystemDropDown(index: number, rowCell: string) {
    if (index > 2) {
      setTimeout(() => {
        const dropdown: any = document.getElementById(`dropdown-open-${index}-${rowCell}`);

      }, 10);
    }

    this.filterDropdown = true;
    this.dropDownIndex = index + 1;
    this.systemdropDownFlag = `dropdown-open-${index}-${rowCell}`;
    this.sitefilterDropdown = false;
    this.siteDropDownIndex = 0;
    this.sitedropDownFlag = '';
  }

  showsiteDropDown(index: number, rowCell: string) {
    if (index > 3) {
      setTimeout(() => {
        const dropdown: any = document.getElementById(`dropdown-open-${index}-${rowCell}`);

      }, 10);
    }
    this.sitefilterDropdown = true;
    this.siteDropDownIndex = index + 1;
    this.sitedropDownFlag = `dropdown-open-${index}-${rowCell}`;
    this.filterDropdown = false;
    this.dropDownIndex = 0;
    this.systemdropDownFlag = '';

  }
  openDropDown(index: number, rowCell: string) {
    const clsName = document.getElementsByClassName('repair-complete-table-container')[0];
    const cellHeight = 40 * index;
    const containerHeight = clsName.getBoundingClientRect().height;
    const heightDiff = containerHeight - cellHeight;
    const fixedHeight = 350.359375;
    this.positionfilterDropdown = true;
    if (rowCell == 'systemRole') {
      this.opensystemdropdown(index, rowCell, heightDiff, fixedHeight);
    }
    else {
      this.opensitedropdown(index, rowCell, heightDiff, fixedHeight);
    }
  }
  opensitedropdown(index: number, rowCell: string, heightDiff: number, fixedHeight: number) {
    if (heightDiff < fixedHeight) {
      if (this.sitedropDownFlag != '') {
        const element: any = document.getElementById(this.sitedropDownFlag);
        if (element != null) {
          element.style.bottom = '';
          this.sitefilterDropdown = false;
          this.siteDropDownIndex = 0;
          this.sitedropDownFlag = '';
        }
        else {
          this.showsiteDropDown(index, rowCell);
        }
      }
      else {
        this.showsiteDropDown(index, rowCell);
      }

    }
    else {
      if (index > 3) {
        setTimeout(() => {
          const dropdown: any = document.getElementById(`dropdown-open-${index}-${rowCell}`);

        }, 10);
      }
      this.sitefilterDropdown = true;
      this.siteDropDownIndex = index + 1;
    }
  }


  opensystemdropdown(index: number, rowCell: string, heightDiff: number, fixedHeight: number) {
    if (heightDiff < fixedHeight) {
      if (this.systemdropDownFlag != '') {
        const element: any = document.getElementById(this.systemdropDownFlag);
        if (element != null) {
          element.style.bottom = '';
          this.filterDropdown = false;
          this.dropDownIndex = 0;
          this.systemdropDownFlag = '';
        }
        else {
          this.showsystemDropDown(index, rowCell);
        }
      }
      else {
        this.showsystemDropDown(index, rowCell);
      }

    }
    else {
      if (index > 2) {
        setTimeout(() => {
          const dropdown: any = document.getElementById(`dropdown-open-${index}-${rowCell}`);

        }, 10);
      }
      this.filterDropdown = true;
      this.dropDownIndex = index + 1;
    }
  }

  filterRole(roleList: { value: string }[], searchsystemRole: string) {
    return roleList.filter((el: { value: string }) => {
      return `${el.value}`.toLowerCase().includes(searchsystemRole.toLowerCase());
    });
  }

  filterSite(siteList: { siteName: string }[], searchSite: string) {
    return siteList.filter((el: { siteName: string }) => {
      return `${el.siteName}`.includes(searchSite);
    });
  }

  selectRole(systemRole: string, index: number, indexRowColumn?: any) {
    this.filterDropdown = false;
    this.searchsystemRole = '';
    this.rowItemsRoaster[index].systemRole.value = systemRole;
    this.systemsRole = systemRole;
    this.updateDateIssuesBlur(systemRole, index);
    if (this.rowItemsRoaster[index].systemRole && this.rowItemsRoaster[index].systemRole.value == 'No Access' && indexRowColumn == 'systemRole') {
      const emailId: any = document.getElementsByClassName(`${index}:email`);
      for (let i = 0; i < emailId.length; i++) {
        if (emailId[i].getAttribute('style')) {
          emailId[i].removeAttribute('style');
        }
      }
    }

  }

  selectPosition(position: string, index: number) {
    this.positionfilterDropdown = false;
    this.searchsystemRole = '';
    this.rowItemsRoaster[index].position.value = position;
    this.updateDateIssuesBlur(position, index);
    this.filterDropdown = false;
  }
  selectSite(siteName: string, siteId: any, index: number,) {
    this.sitesId = siteId;
    this.sitefilterDropdown = false;
    this.filterDropdown = false;
    this.searchsystemRole = '';
    this.rowItemsRoaster[index].siteName.value = siteName;
    this.rowItemsRoaster[index].siteName.id = siteId;
    this.updateDateIssuesBlur(siteName, index);

  }
}